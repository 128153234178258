import { global } from ":global";
import { _Firebase } from "./_model";
import { AWordCloud } from "../_model";

export async function addNew(props: { clouds: AWordCloud[]; new: AWordCloud }) {
  const clouds = props.clouds ?? [];
  const id = String(clouds.length);
  // clouds.push(props.new)
  return global.fb.rtdb.set({
    path: `/wordcloud/${id}`,
    value: props.new,
  });
}

export async function updateQuestion(props: { id: string; question: string }) {
  const id = String(props.id);
  return global.fb.rtdb.set({
    path: `/wordcloud/${id}/question`,
    value: props.question,
  });
}

export async function onAllChanged(
  callback: _Firebase.OnWorldCloudUpdateProps["onUpdate"]
) {
  global.fb.rtdb.onValueChanged({
    path: "/wordcloud/",
    callback: (snap) => {
      callback?.(snap.val());
    },
  });
}

export async function deleteAll() {
  global.fb.rtdb.set({
    path: "/wordcloud/",
    value: [],
  });
}

export async function deleteID(id: number | string) {
  global.fb.rtdb.set({
    path: `/wordcloud/${String(id)}`,
    value: null,
  });
}
